exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-amazon-comparison-shopping-js": () => import("./../../../src/pages/case-studies/amazon/comparison-shopping.js" /* webpackChunkName: "component---src-pages-case-studies-amazon-comparison-shopping-js" */),
  "component---src-pages-case-studies-atlassian-personalized-question-asking-experience-js": () => import("./../../../src/pages/case-studies/atlassian/personalized-question-asking-experience.js" /* webpackChunkName: "component---src-pages-case-studies-atlassian-personalized-question-asking-experience-js" */),
  "component---src-pages-case-studies-atlassian-what-goes-into-trusting-an-answer-er-js": () => import("./../../../src/pages/case-studies/atlassian/what-goes-into-trusting-an-answer-er.js" /* webpackChunkName: "component---src-pages-case-studies-atlassian-what-goes-into-trusting-an-answer-er-js" */),
  "component---src-pages-case-studies-boilerplate-js": () => import("./../../../src/pages/case-studies/boilerplate.js" /* webpackChunkName: "component---src-pages-case-studies-boilerplate-js" */),
  "component---src-pages-case-studies-hubspot-hubspot-network-js": () => import("./../../../src/pages/case-studies/hubspot/hubspot-network.js" /* webpackChunkName: "component---src-pages-case-studies-hubspot-hubspot-network-js" */),
  "component---src-pages-case-studies-improving-the-question-creation-experience-with-personalization-techniques-js": () => import("./../../../src/pages/case-studies/improving-the-question-creation-experience-with-personalization-techniques.js" /* webpackChunkName: "component---src-pages-case-studies-improving-the-question-creation-experience-with-personalization-techniques-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-studies-shopify-automatic-vs-manual-fx-rates-js": () => import("./../../../src/pages/case-studies/shopify/automatic-vs-manual-fx-rates.js" /* webpackChunkName: "component---src-pages-case-studies-shopify-automatic-vs-manual-fx-rates-js" */),
  "component---src-pages-case-studies-shopify-multi-currency-payment-methods-js": () => import("./../../../src/pages/case-studies/shopify/multi-currency-payment-methods.js" /* webpackChunkName: "component---src-pages-case-studies-shopify-multi-currency-payment-methods-js" */),
  "component---src-pages-case-studies-what-goes-into-trusting-an-answerer-js": () => import("./../../../src/pages/case-studies/what-goes-into-trusting-an-answerer.js" /* webpackChunkName: "component---src-pages-case-studies-what-goes-into-trusting-an-answerer-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-design-process-js": () => import("./../../../src/pages/my-design-process.js" /* webpackChunkName: "component---src-pages-my-design-process-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

